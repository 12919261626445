import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";

import "./LegalPage.css";

const jobRoles = [
  "Chief Software Developer",
  "Head of Marketing",
  "Head of Sales",
  "Head of Travel Writing",
  "Head of SEO and Organic Growth",
  "Head of Customer Service and UX",
  "Head of Finance",
];

const Jobs = ({ pageContext }) => {
  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Jobs</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            {jobRoles.map((jobRole) => (
              <>
                <div style={{ padding: "40px 0" }}>
                  <h2 style={{ marginBottom: 20 }}>{jobRole}</h2>
                  <a
                    href="mailto:james@travel-lingual.com"
                    style={{ textDecorationLine: "none" }}
                  >
                    <Button secondary>Apply now</Button>
                  </a>
                </div>

                <hr />
              </>
            ))}
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default Jobs;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Jobs | Travel-Lingual"}
      description={
        "Explore exciting opportunities in the travel industry at Travel Lingual's Jobs Page. Join a dynamic team and make your career journey a global adventure!"
      }
      pathname={location.pathname}
    />
  );
};
